<script>
	import GlobalStyles from './styles/GlobalStyles.svelte';
    import ChooseLocation from "./components/ChooseLocation.svelte";
	import PrediccioHoraria from "./components/PrediccioHoraria.svelte";
	import Prediccio8Dies from "./components/Prediccio8Dies.svelte";
	import Spinner from "./components/Spinner.svelte";
	import { configureStorage, getCurrentMunicipi, getStoredMunicipi, fetchPronostic, getSavedPronostic, getSunTimes, getMunicipis } from "./core";
	import { setContext } from "svelte";
	import { Storage } from "@capacitor/storage";

	let loading = true;
	let locating = true;

	let municipis;
	let municipi;
	let location;
	let pronostic;

	const ini = async () => {
		await configureStorage();

		getMunicipis().then(value => {
			if(value){
				municipis = value;

				getCurrentMunicipi(municipis).then(value => {
					municipi = location = value;
					locating = false;
				});
			}
		});

		getSavedPronostic().then(value => {
			if(value){
				pronostic = value;
				loading = false;
			}
		});

		getStoredMunicipi().then(value => {
			municipi = value;
		});
	}

	ini();

	$: if(municipi?.codi){
		loading = true;

		setContext('suntimes', getSunTimes(municipi.coordenades.latitud, municipi.coordenades.longitud));

		fetchPronostic(municipi?.codi).then(value => {
			if(value){
				pronostic = value;
				loading = false;
			}
		});
	}

	const handleVisibilityChange = () => {
		if(document.visibilityState === 'visible'){
			locating = true;

			getCurrentMunicipi(municipis).then(value => {
				if(value === municipi){
					fetchPronostic(municipi?.codi).then(value => {
						if(value){
							pronostic = value;
							loading = false;
						}
					});
				}else{
					municipi = location = value;
				}

				locating = false;
			});
		}
	};

	const handleChangeMunicipi = (city) => {
		municipi = city;

		Storage.set({
			key: 'municipi',
			value: JSON.stringify(city)
		});
	};
</script>


<svelte:window on:visibilitychange={handleVisibilityChange} on:oncontextmenu|preventDefault|stopPropagation={() => false}/>

<main>
	<div class="pronostic">
		{#if loading && !pronostic}
			<div class="loading"><Spinner /></div>
		{:else}
			<PrediccioHoraria {pronostic} />
			<Prediccio8Dies {pronostic} />
		{/if}
	</div>

	<ChooseLocation {municipis} current={municipi} {location} {locating} loading={loading && pronostic} {handleChangeMunicipi} />
</main>


<style lang="scss">
	main{
		height: 100vh;
		max-height: calc(100vh - env(safe-area-inset-bottom));
		overflow: hidden;
	}

	.pronostic{
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
		height: calc(100vh - 48px - env(safe-area-inset-bottom));
		width: 100%;
		max-width: 640px;
		margin-left: auto;
		margin-right: auto;
	}

	.loading{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
</style>
