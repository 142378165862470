<script>
	import { parse} from 'date-fns';
	import Cel from "../icons/cel/Cel.svelte";

	export let pronostic;

	let dies;

	$: if(pronostic?.dies){
		let avui = new Date();

		avui.setHours(0, 0, 0, 0);

		dies = pronostic.dies.filter(dia => {
			const date = parse(dia.data, 'yyyy-MM-ddX', new Date());

			return date >= avui;
		});
	}
</script>


<div class="prediccio">
	<ul>
		{#if dies}
			{#each dies as dia, i}
				<li>
					<div class="dia">{dia.dia}</div>
					<div class="cel"><Cel codi={dia.cel} width="40" /></div>
					<div class="precipitacio">{dia.precipitacio >= 15 ? dia.precipitacio + '%' : ''}</div>
					<div class="tminmax">{dia.tmin}º<span>—</span>{dia.tmax}º</div>
				</li>
			{/each}
		{/if}
	</ul>
</div>


<style lang="scss">
	.prediccio{
		overflow-y: auto;
		max-width: 460px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
	}

	ul{
		list-style: none;
		padding: 15px 20px;
		margin: 0;
	}

	li{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 10px;
		grid-row-gap: 0;
		align-items: center;
		margin-bottom: 7px;

		.dia{
			grid-column: span 2;
		}

		.cel{
			text-align: center;
		}

		.precipitacio{
			font-size: .7rem;
			opacity: .6;
			font-feature-settings: "kern" 1, "liga" 1, "ss01" 1, "calt" 1, "tnum" 1;
		}

		.tminmax{
			text-align: right;
			white-space: nowrap;
			font-feature-settings: "kern" 1, "liga" 1, "ss01" 1, "calt" 1, "tnum" 1;

			span{
				opacity: .3;
				margin-left: 3px;
				margin-right: 3px;
			}
		}
	}
</style>
