<script>
	import { getEstatCelIcon } from "../../core";
	import { getContext } from "svelte";

	export let codi;
	export let hora = 12;
	export let width = null;
	export let height = null;
	export let style = null;

	let suntimes = getContext('suntimes');
</script>

<img src={getEstatCelIcon(codi, hora, suntimes)} {width} {height} {style} alt="">

<!--
{#if codi === 1}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M256 143.7c-61.8 0-112 50.3-112 112.1s50.2 112.1 112 112.1 112-50.3 112-112.1-50.2-112.1-112-112.1zm0 192.2c-44.1 0-80-35.9-80-80.1s35.9-80.1 80-80.1 80 35.9 80 80.1-35.9 80.1-80 80.1zm256-80.1c0-5.3-2.7-10.3-7.1-13.3L422 187l19.4-97.9c1-5.2-.6-10.7-4.4-14.4-3.8-3.8-9.1-5.5-14.4-4.4l-97.8 19.4-55.5-83c-6-8.9-20.6-8.9-26.6 0l-55.5 83-97.8-19.5c-5.3-1.1-10.6.6-14.4 4.4-3.8 3.8-5.4 9.2-4.4 14.4L90 187 7.1 242.5c-4.4 3-7.1 8-7.1 13.3 0 5.3 2.7 10.3 7.1 13.3L90 324.6l-19.4 97.9c-1 5.2.6 10.7 4.4 14.4 3.8 3.8 9.1 5.5 14.4 4.4l97.8-19.4 55.5 83c3 4.5 8 7.1 13.3 7.1s10.3-2.7 13.3-7.1l55.5-83 97.8 19.4c5.4 1.2 10.7-.6 14.4-4.4 3.8-3.8 5.4-9.2 4.4-14.4L422 324.6l82.9-55.5c4.4-3 7.1-8 7.1-13.3zm-116.7 48.1c-5.4 3.6-8 10.1-6.8 16.4l16.8 84.9-84.8-16.8c-6.6-1.4-12.8 1.4-16.4 6.8l-48.1 72-48.1-71.9c-3-4.5-8-7.1-13.3-7.1-1 0-2.1.1-3.1.3l-84.8 16.8 16.8-84.9c1.2-6.3-1.4-12.8-6.8-16.4l-71.9-48.1 71.9-48.2c5.4-3.6 8-10.1 6.8-16.4l-16.8-84.9 84.8 16.8c6.5 1.3 12.8-1.4 16.4-6.8l48.1-72 48.1 72c3.6 5.4 9.9 8.1 16.4 6.8l84.8-16.8-16.8 84.9c-1.2 6.3 1.4 12.8 6.8 16.4l71.9 48.2-71.9 48z" class=""></path></svg>
{:else if codi === 2}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M576 224c-12 0-23.1 3.5-32.7 9.2-3.5-41-37.4-73.2-79.3-73.2-38.8 0-71.1 27.6-78.4 64.2-.5 0-1-.2-1.6-.2-35.3 0-64 28.7-64 64s28.7 64 64 64h192c35.3 0 64-28.7 64-64s-28.7-64-64-64zM429.4 384H384c-22.2 0-42.4-7.9-58.6-20.5-49.6 32-116.5 26.4-159.9-16.9-49.9-49.9-49.9-131.1 0-181s131.1-49.9 181 0c7.2 7.2 13 15.1 18.1 23.4 13.2-25.5 35.6-45 62.6-54.5l13.9-41.7c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.3-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9L429.4 384zm-92.5-179.3C319.8 177.9 290 160 256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96c17.2 0 33.1-4.9 47.1-12.8-9.4-14.8-15.1-32.3-15.1-51.2 0-35.8 19.8-66.8 48.9-83.3z" class=""></path></svg>
{:else if codi === 3}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M640 236.8c0-50.8-38.5-92.9-87.8-98.6C532 103.4 494.4 80 452 80c-46 0-85.9 26.4-104.5 66-31.7 13.8-54.4 43.6-58.6 79-22.3 12.7-40.7 31.8-52.1 55.3C191.4 297.6 160 341.1 160 392c0 66.2 53.8 120 120 120h240c66.2 0 120-53.8 120-120 0-32-12.8-60.8-33.3-82.3 20.1-18.1 33.3-43.7 33.3-72.9zM520 464H280c-39.8 0-72-32.2-72-72 0-37.6 28.9-68 65.5-71.3C280.7 283.9 313 256 352 256c23.9 0 45.1 10.7 59.8 27.3 10.3-7.1 22.8-11.3 36.2-11.3 30.2 0 55.3 20.9 62 49 3.3-.5 6.5-1 10-1 39.8 0 72 32.2 72 72s-32.2 72-72 72zm45.6-182.7c-7.8-3.2-15.9-6-24.5-7.5-20.3-30.5-54.7-49.8-93.1-49.8-9.6 0-19.2 1.2-28.3 3.6-20.2-12.7-43.6-19.6-67.7-19.6-2.2 0-4.2.4-6.4.5 8.8-12.9 22.8-21.9 39.4-22.6 4.9-32.7 32.9-57.8 67-57.8 35.8 0 64.8 27.8 67.5 62.9 6.5-3.1 13.6-5.3 21.3-5.3 28.3 0 51.2 22.9 51.2 51.2 0 19.1-10.7 35.7-26.4 44.4zM115 297.4l6-31.1-26.2-17.8-37-25.1 37-25.1 26.2-17.8-6-31.1-8.5-43.9 43.7 8.5 31.2 6 17.8-26.3L224 57v1l24.9 36.9 17.8 26.3 31.2-6 35.1-6.8c13.1-18 29.9-32.5 49.2-42.8-6.6-6.5-15.4-10.2-24.6-10.2-2.2 0-4.5.2-6.8.6l-62 12-35.4-52.4C246.7 5.8 235.8 0 224 0c-11.4 0-22.7 4.9-29.3 14.7l-35.4 52.4-62-12c-2.3-.4-4.5-.7-6.8-.7-9.3 0-18.3 3.7-25 10.4-8.3 8.4-11.9 20.2-9.7 31.8l12 62.1-52.3 35.5C5.8 200.8 0 211.8 0 223.5c0 11.8 5.8 22.7 15.6 29.3l52.3 35.4-12 62.1c-2.2 11.6 1.4 23.5 9.7 31.8 6.7 6.7 15.6 10.4 25 10.4 2.2 0 4.5-.2 6.8-.6l31.1-6c.7-17.9 4.4-35.1 10.8-51l-32.7 6.3 8.4-43.8zM224 184c15.5 0 28.7 9.1 35.3 22.1.3-.2.6-.4.8-.6 4.2-17.1 11.6-33.1 22-47.1-15.5-13.7-35.7-22.4-58.1-22.4-48.5 0-88 39.5-88 88 0 26.7 12.2 50.3 31 66.5 11.1-12.3 24.4-22.7 39.5-31C193.3 253 184 239.7 184 224c0-22.1 17.9-40 40-40z" class=""></path></svg>
{:else if codi === 4}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M571.7 238.8c2.8-9.9 4.3-20.2 4.3-30.8 0-61.9-50.1-112-112-112-16.7 0-32.9 3.6-48 10.8-31.6-45-84.3-74.8-144-74.8-94.4 0-171.7 74.5-175.8 168.2C39.2 220.2 0 274.3 0 336c0 79.6 64.4 144 144 144h368c70.7 0 128-57.2 128-128 0-47-25.8-90.8-68.3-113.2zM512 448H144c-61.9 0-112-50.1-112-112 0-56.8 42.2-103.7 97-111-.7-5.6-1-11.3-1-17 0-79.5 64.5-144 144-144 60.3 0 111.9 37 133.4 89.6C420 137.9 440.8 128 464 128c44.2 0 80 35.8 80 80 0 18.5-6.3 35.6-16.9 49.2C573 264.4 608 304.1 608 352c0 53-43 96-96 96z" class=""></path></svg>
{:else if codi === 5}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M48 360c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16s16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm96 80c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16s16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm96-80c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16s16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm96 80c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16s16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm96-80c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16s16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm-16.3-247.7C411.8 67.4 373.9 32 328 32c-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7zM408 288H104c-39.7 0-72-32.3-72-72 0-32.3 21.9-60.7 53.3-69.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72S447.7 288 408 288z" class=""></path></svg>
{:else if codi === 6}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M415.7 112.3C411.8 67.4 373.9 32 328 32c-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7zM408 288H104c-39.7 0-72-32.3-72-72 0-32.3 21.9-60.7 53.3-69.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72S447.7 288 408 288zM48 368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm96 32c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm96-32c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm96 32c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm96-32c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16z" class=""></path></svg>
{:else if codi === 7}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M183.9 370.1c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8zm96 0c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8zm-192 0c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8zm384 0c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8zm-96 0c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8zm39.8-257.8C411.8 67.4 373.9 32 328 32c-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.9 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7zM408 288H104c-39.7 0-72-32.3-72-72 0-32.3 21.9-60.7 53.3-69.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72S447.7 288 408 288z" class=""></path></svg>
{:else if codi === 8}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M368 256h-73.8l25-74.9c1.6-4.9.8-10.2-2.2-14.4-3-4.2-7.8-6.6-13-6.6H192c-7.7 0-14.3 5.5-15.8 13.1l-32 176c-.8 4.7.4 9.5 3.5 13.1s7.5 5.8 12.3 5.8h80v128c0 7.4 5.1 13.9 12.3 15.6 1.2.3 2.5.4 3.7.4 6 0 11.6-3.3 14.3-8.8l112-224c2.5-5 2.2-10.8-.7-15.6-2.9-4.8-8.1-7.7-13.6-7.7zm-96 172.2V352c0-8.8-7.2-16-16-16h-76.8l26.2-144h76.5l-25 74.9c-1.6 4.9-.8 10.2 2.2 14.4 3 4.2 7.8 6.6 13 6.6h70.1L272 428.2zm143.7-315.9C411.8 67.4 373.9 32 328 32c-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104h13l5.8-32H104c-39.7 0-72-32.3-72-72 0-32.3 21.9-60.7 53.3-69.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72 0 38-29.7 68.9-67 71.5-.7 2-1.1 4-2.1 6L397.7 320H408c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7z" class=""></path></svg>
{:else if codi === 9}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M415.7 112.3C411.8 67.4 373.9 32 328 32c-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7zM408 288H104c-39.7 0-72-32.3-72-72 0-32.3 21.9-60.7 53.3-69.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72S447.7 288 408 288zm-224.1 82.1c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8zM216 464c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zM87.2 369.7c-7.9-3.9-17.5-.8-21.5 7.2l-16 32c-3.9 7.9-.8 17.5 7.2 21.5 2.3 1.1 4.8 1.7 7.2 1.7 5.8 0 11.5-3.2 14.3-8.8l16-32c3.8-8.1.7-17.7-7.2-21.6zM24 464c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm447.2-94.3c-7.9-3.9-17.5-.8-21.5 7.2l-16 32c-3.9 7.9-.8 17.5 7.2 21.5 2.3 1.1 4.8 1.7 7.2 1.7 5.8 0 11.5-3.2 14.3-8.8l16-32c3.8-8.1.7-17.7-7.2-21.6zm-192 0c-7.9-3.9-17.5-.8-21.5 7.2l-16 32c-3.9 7.9-.8 17.5 7.2 21.5 2.3 1.1 4.8 1.7 7.2 1.7 5.8 0 11.5-3.2 14.3-8.8l16-32c3.8-8.1.7-17.7-7.2-21.6zM408 464c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm-32.1-93.9c-7.6-4.4-17.4-1.8-21.8 6l-64 112c-4.4 7.7-1.7 17.5 6 21.8 2.5 1.4 5.2 2.1 7.9 2.1 5.5 0 10.9-2.9 13.9-8.1l64-112c4.4-7.6 1.7-17.4-6-21.8z" class=""></path></svg>
{:else if codi === 10}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M126.9 389.1l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-28 16.1V360c0-4.4-3.6-8-8-8H56c-4.4 0-8 3.6-8 8v28.4l-28-16.1c-3.8-2.2-8.7-.9-10.9 2.9l-8 13.9c-2.2 3.8-.9 8.7 2.9 10.9l27.9 16L4 432c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.9c2.2 3.8 7.1 5.1 10.9 2.9l28-16.1V472c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-28.4l28 16.1c3.8 2.2 8.7.9 10.9-2.9l8-13.9c2.2-3.8.9-8.7-2.9-10.9l-27.9-16 27.9-16c3.8-2.2 5.1-7.1 2.9-10.9zm384 0l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-28 16.1V360c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v28.4l-28-16.1c-3.8-2.2-8.7-.9-10.9 2.9l-8 13.9c-2.2 3.8-.9 8.7 2.9 10.9l27.9 16-27.9 16c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.9c2.2 3.8 7.1 5.1 10.9 2.9l28-16.1V472c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-28.4l28 16.1c3.8 2.2 8.7.9 10.9-2.9l8-13.9c2.2-3.8.9-8.7-2.9-10.9l-27.9-16 27.9-16c3.8-2.2 5.1-7.1 2.9-10.9zm-192 32l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-28 16.1V392c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v28.4l-28-16.1c-3.8-2.2-8.7-.9-10.9 2.9l-8 13.9c-2.2 3.8-.9 8.7 2.9 10.9l27.9 16-27.9 16c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.9c2.2 3.8 7.1 5.1 10.9 2.9l28-16.1V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-28.4l28 16.1c3.8 2.2 8.7.9 10.9-2.9l8-13.9c2.2-3.8.9-8.7-2.9-10.9l-27.9-16 27.9-16c3.8-2.2 5.1-7.1 2.9-10.9zM104 320h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7-4-44.9-41.8-80.3-87.7-80.3-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104zM85.3 146.8l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72s-32.3 72-72 72H104c-39.7 0-72-32.3-72-72 0-32.4 21.9-60.8 53.3-69.3z" class=""></path></svg>
{:else if codi === 11}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M168 320h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7C475.8 67.4 437.9 32 392 32c-17.8 0-34.8 5.3-49.2 15.2C320.3 17.7 285.5 0 248 0c-66.2 0-120 53.8-120 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104zm-18.7-173.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L351 82.1C361.9 70.4 376.4 64 392 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72s-32.3 72-72 72H168c-39.7 0-72-32.3-72-72 0-32.4 21.9-60.8 53.3-69.3zM216 480H72c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm416 0H296c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h336c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-56-72v-16c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h560c4.4 0 8-3.6 8-8z" class=""></path></svg>
{:else if codi === 12}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M168 320h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7C475.8 67.4 437.9 32 392 32c-17.8 0-34.8 5.3-49.2 15.2C320.3 17.7 285.5 0 248 0c-66.2 0-120 53.8-120 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104zm-18.7-173.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L351 82.1C361.9 70.4 376.4 64 392 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72s-32.3 72-72 72H168c-39.7 0-72-32.3-72-72 0-32.4 21.9-60.8 53.3-69.3zM216 480H72c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm416 0H296c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h336c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-56-72v-16c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h560c4.4 0 8-3.6 8-8z" class=""></path></svg>
{:else if codi === 13}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M126.9 389.1l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-28 16.1V360c0-4.4-3.6-8-8-8H56c-4.4 0-8 3.6-8 8v28.4l-28-16.1c-3.8-2.2-8.7-.9-10.9 2.9l-8 13.9c-2.2 3.8-.9 8.7 2.9 10.9l27.9 16L4 432c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.9c2.2 3.8 7.1 5.1 10.9 2.9l28-16.1V472c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-28.4l28 16.1c3.8 2.2 8.7.9 10.9-2.9l8-13.9c2.2-3.8.9-8.7-2.9-10.9l-27.9-16 27.9-16c3.8-2.2 5.1-7.1 2.9-10.9zm384 0l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-28 16.1V360c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v28.4l-28-16.1c-3.8-2.2-8.7-.9-10.9 2.9l-8 13.9c-2.2 3.8-.9 8.7 2.9 10.9l27.9 16-27.9 16c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.9c2.2 3.8 7.1 5.1 10.9 2.9l28-16.1V472c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-28.4l28 16.1c3.8 2.2 8.7.9 10.9-2.9l8-13.9c2.2-3.8.9-8.7-2.9-10.9l-27.9-16 27.9-16c3.8-2.2 5.1-7.1 2.9-10.9zm-192 32l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-28 16.1V392c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v28.4l-28-16.1c-3.8-2.2-8.7-.9-10.9 2.9l-8 13.9c-2.2 3.8-.9 8.7 2.9 10.9l27.9 16-27.9 16c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.9c2.2 3.8 7.1 5.1 10.9 2.9l28-16.1V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-28.4l28 16.1c3.8 2.2 8.7.9 10.9-2.9l8-13.9c2.2-3.8.9-8.7-2.9-10.9l-27.9-16 27.9-16c3.8-2.2 5.1-7.1 2.9-10.9zM104 320h304c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7-4-44.9-41.8-80.3-87.7-80.3-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104zM85.3 146.8l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72s-32.3 72-72 72H104c-39.7 0-72-32.3-72-72 0-32.4 21.9-60.8 53.3-69.3z" class=""></path></svg>
{:else if codi === 20}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}><path fill="currentColor" d="M640 258.3c0-51.6-40.2-93.5-89.6-93.5h-.5c-16.6-40.7-55.3-69.2-99.4-69.2-46.4 0-86.2 30.1-101.5 74.2-25.3 8.8-45.2 29.1-54.7 54.9-48.3 5-86.3 45.5-86.3 95.1 0 1.8.1 3.7.2 5.6-38 13.2-64.2 49.2-64.2 90.5 0 53 43.1 96.1 96 96.1h240c52.9 0 96-43.1 96-96.1 0-24.7-9.4-47.1-24.8-64.1 49-.5 88.8-42.2 88.8-93.5zM480 480H240c-35.3 0-64-28.7-64-64.1 0-31 22.2-57.5 52.8-62.9l16.5-2.9-3.6-16.3c-1.1-5-1.7-9.6-1.7-13.9 0-35.3 28.7-64.1 64-64.1 24.6 0 47.2 14.5 57.8 37l8.6 18.4 15.8-12.7c8.8-7 19.1-10.7 29.8-10.7 26.5 0 48 21.5 48 48v16h16c35.3 0 64 28.7 64 64.1S515.3 480 480 480zm70.4-160.2h-56.1c-7.5-36.5-39.7-64.1-78.3-64.1-12 0-23.7 2.7-34.4 7.9-13-18-32-30.8-53.2-36.5 9.6-17.3 26.7-29.1 46.7-30.1 5.6-39.3 37-69.4 75.3-69.4 40.3 0 72.9 33.3 75.9 75.5 7.4-3.7 15.3-6.3 24-6.3 31.8 0 57.6 27.5 57.6 61.5.1 34-25.7 61.5-57.5 61.5zm-384.9-7.7c-2.1-1-4.3-1.7-6.6-1.7-1 0-2.1.1-3.1.3l-63.2 12.5 12.5-63.2c1.2-6.3-1.4-12.8-6.8-16.5l-53.5-35.8 53.5-35.8c5.4-3.6 8-10.1 6.8-16.5L92.6 92.2l63.2 12.5c6.5 1.3 12.8-1.5 16.4-6.8L208 44.4 243.8 98c3.6 5.3 9.9 8.1 16.4 6.8l63.2-12.5-12.1 61.2c4.2-2.8 8.4-5.5 13-7.8 7.3-15.7 17.5-29.4 29.3-41.2l5.8-29.5c1-5.2-.6-10.6-4.4-14.4-3.8-3.8-9-5.5-14.4-4.4l-76.2 15.1-43.1-64.6c-6-8.9-20.6-8.9-26.6 0l-43.2 64.6-76.2-15.2c-5.3-1.1-10.6.7-14.4 4.4-3.8 3.8-5.4 9.2-4.4 14.4l15.1 76.3-64.5 43.2c-4.4 3-7.1 8-7.1 13.3C0 213 2.7 218 7.1 221l64.5 43.2-15.1 76.2c-1 5.3.6 10.7 4.4 14.4s9.2 5.4 14.4 4.4l56.3-11.1c8.8-14 20.3-26.3 33.9-36zM208 149.8c28.8 0 52.5 21.2 56.9 48.8 2.4-.8 4.8-1.8 7.3-2.4 5-9 11.2-17.1 18-24.5-13.9-31.7-45.5-53.9-82.2-53.9-49.5 0-89.8 40.3-89.8 89.9 0 39.5 25.7 72.7 61.1 84.8 2.3-10.6 5.9-20.6 10.6-30.1-23-7.6-39.8-29.1-39.8-54.7.1-31.9 26-57.9 57.9-57.9z" class=""></path></svg>
{:else if codi === 21}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 22}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 23}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 24}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 25}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 26}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 27}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 28}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 29}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 30}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 31}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{:else if codi === 22}
	<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} {style}></svg>
{/if}
-->
