import { Geolocation } from "@capacitor/geolocation";
import { Storage } from '@capacitor/storage';
import API from "./API";
import CONFIG from "./config";
import { getSunrise, getSunset } from "sunrise-sunset-js";

export const configureStorage = async () => {
	await Storage.configure({
		group: 'cat.nye.meteo'
	});
};

export const getMunicipis = async () => {
	const stored_municipis = await Storage.get({key: 'municipis'});

	if(stored_municipis?.value){
		return JSON.parse(stored_municipis.value);
	}else{
		const municipis = await API.get('/referencia/v1/municipis');

		Storage.set({
			key: 'municipis',
			value: JSON.stringify(municipis)
		});

		return municipis;
	}
};

export const getStoredMunicipi = async () => {
	const stored_municipi = await Storage.get({key: 'municipi'});

	if(stored_municipi){
		return JSON.parse(stored_municipi.value);
	}else{
		return CONFIG.DEFAULT_CITY;
	}
};

export const getCurrentMunicipi = async (municipis) => {
	const coordinates = await Geolocation.getCurrentPosition();
	const guessed = await API.get('/here/reverse?at='+ coordinates.coords.latitude + ',' + coordinates.coords.longitude);

	if(guessed?.items.length){
		const guessed_municipi = guessed.items[0].address.city;

		if(guessed_municipi){
			const real_municipi = municipis.find(o => o.nom === guessed_municipi);

			if(real_municipi){
				Storage.set({
					key: 'municipi',
					value: JSON.stringify(real_municipi)
				});

				return real_municipi;
			}
		}
	}

	const stored_municipi = await Storage.get({key: 'municipi'});

	if(stored_municipi){
		return JSON.parse(stored_municipi.value);
	}else{
		return CONFIG.DEFAULT_CITY;
	}
};

export const getSavedPronostic = async () => {
	const pronostic = await Storage.get({key: 'pronostic'});

	if(pronostic){
		return JSON.parse(pronostic.value);
	}else{
		return null;
	}
};

export const fetchPronostic = async (codimunicipi) => {
	const response = await API.get('/pronostic/' + codimunicipi);

	Storage.set({key: 'pronostic', value: JSON.stringify(response)});

	return response;
}

export const getPrediccioMunicipalHoraria = async (codimunicipi) => {
	const today = new Date();
	const response = await API.get('/pronostic/v1/municipalHoraria/' + codimunicipi);

	let ret = [];
	let max = 0;

	if(response?.dies){
		for(let i = 0; i < response.dies.length; i++){
			const dia = response.dies[i];

			for(let j = 0; j < dia.variables.temp.valors.length; j++){
				const temp = dia.variables.temp.valors[j];
				const cel = dia.variables.estatCel.valors[j];
				const date = new Date(temp.data);

				if(max === 30) break;

				if(date.getHours() >= today.getHours() || date.getTime() >= today.getTime()){
					ret.push({
						date: date,
						hour: date.getHours(),
						temp: temp.valor,
						cel: cel.valor
					});
					max++;
				}
			}
		}
	}

	return ret;
};

export const getPrediccio8Dies = async (codimunicipi) => {
	const response = await API.get('/pronostic/v1/municipal/' + codimunicipi);
	const today = new Date();

	let ret = [];

	if(response?.dies){
		for(let i = 0; i < response.dies.length; i++){
			const vars = response.dies[i].variables;
			const date = new Date(response.dies[i].data);

			if(date.setHours(0, 0, 0, 0) === today.setHours(0,0,0,0)) continue;

			ret.push({
				date: date,
				dia: date.toLocaleDateString('ca', { weekday: 'long' }),
				tmin: Math.round(vars.tmin.valor),
				tmax: Math.round(vars.tmax.valor),
				cel: vars.estatCel.valor,
				precipitacio: vars.precipitacio.valor
			});
		}
	}

	return ret;
};

export const getEstatCelIcon = (codi, hora = null, suntimes = [7, 21]) => {
	const icon = CONFIG.ICONS.find(o => parseInt(o.codi) === codi);

	if(icon){
		if((hora >= suntimes[1] || hora <= suntimes[0]) && hora !== null){
			return icon.icona_nit;
		}else{
			return icon.icona;
		}
	}else{
		return 'default';
	}
}

export const getSunTimes = (lat, long) => {
	const d1 = getSunrise(lat, long);
	const d2 = getSunset(lat, long);

	let sunrise = d1.getHours() + (d1.getMinutes() * 100 / 60) / 100;
	let sunset = d2.getHours() + (d2.getMinutes() * 100 / 60) / 100;

	return [sunrise, sunset];
}

export const getFavs = async () => {
	const favs = await Storage.get({key: 'favs'});

	return JSON.parse(favs.value);
}

export const addFav = async (municipi) => {
	let favs = await Storage.get({key: 'favs'});

	if(favs.value) favs = JSON.parse(favs.value);
	else favs = [];
	favs.push(municipi);
	await Storage.set({key: 'favs', value: JSON.stringify(favs)});
}

export const removeFav = async (codi_municipi) => {
	let favs = await Storage.get({key: 'favs'});

	if(favs.value){
		favs = JSON.parse(favs.value);
		favs = favs.filter(obj => obj.codi !== codi_municipi);
		await Storage.set({key: 'favs', value: JSON.stringify(favs)});
	}
}
