import {Workbox} from 'workbox-window';
import App from './App.svelte';

const app = new App({
	target: document.body,
	props: {}
});

export default app;

if('serviceWorker' in navigator){
	const wb = new Workbox('/service-worker.js');

	wb.register();
}
