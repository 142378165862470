<script>
	import LocationArrow from "../icons/LocationArrow.svelte";
	import Spinner from "./Spinner.svelte";
	import Heart from "../icons/Heart.svelte";
	import { getFavs, addFav, removeFav, configureStorage } from "../core";;
	import AngleDown from "../icons/AngleDown.svelte";
	import Search from "../icons/Search.svelte";

	export let locating = false;
	export let loading = false;

	export let municipis;
	export let current;
	export let location;
	export let handleChangeMunicipi;

	let favorits;
	let search;
	let open = false

	const ini = async () => {
		await configureStorage();
		favorits = await getFavs();
	}

	ini();

	const handeCurrentClick = () => {
		open = !open;
	}

	const handleMunicipiClick = (municipi) => {
		open = false;
		handleChangeMunicipi(municipi);
	}

	const handleAddFavClick = async (municipi) => {
		await addFav(municipi);
		favorits = await getFavs();
	}

	const handleRemoveFavClick = async (codi) => {
		await removeFav(codi);
		favorits = await getFavs();
	}

	$: if(municipis && favorits){
		let favs = favorits.map(obj => obj.codi);
		municipis = municipis.filter(obj => !favs.includes(obj.codi));
	}

	$: showArrow = location === current;

	$: filtered = !search ? municipis : municipis.filter(municipi => municipi.nom.toLowerCase().includes(search.toLowerCase()));
</script>


<div class="choose-location" class:locating class:open>
    <div class="holder">
		<div class="current" on:click|preventDefault={handeCurrentClick}>
			{#if showArrow || locating}<LocationArrow width="18" pulse={locating}/>{/if} <span>{current ? current.nom : '...'}</span>
			{#if loading}<div class="loading"><Spinner width="20" height="20"/></div>{/if}
			{#if open}<div class="close"><AngleDown width="20" height="20"/></div>{/if}
		</div>

		<div class="lists">
			<ul class="favs">
				{#if location}
					<li style="padding-right: 3px"><button type="button" on:click|preventDefault={() => handleMunicipiClick(location)} style="margin-right: 0">Ubicació actual <span class="muted">({location.nom})</span></button></li>
				{/if}

				{#await favorits then favs}
					{#if favs}
						{#each favs as fav (fav.codi)}
							<li><button type="button" on:click|preventDefault={() => handleMunicipiClick(fav)}>{fav.nom}</button> <strong><Heart width="20" checked on:click={() => handleRemoveFavClick(fav.codi)} /></strong></li>
						{/each}
					{/if}
				{/await}
			</ul>

			<div class="search">
				<Search width="18" height="18" style="position: absolute; top: 9px; left: 7px;" />
				<input type="search" bind:value={search} placeholder="Buscar..." />
			</div>

			<ul class="list">
				{#if filtered}
					{#each filtered as municipi (municipi.codi)}
						<li><button type="button" on:click|preventDefault={() => handleMunicipiClick(municipi)}>{municipi.nom}</button> <Heart width="20" on:click={() => handleAddFavClick(municipi)} /></li>
					{/each}
				{/if}
			</ul>
		</div>
	</div>
</div>


<style lang="scss">
    .choose-location{
		position: sticky;
		right: 0;
		bottom: env(safe-area-inset-bottom);
		left: 0;
		height: 48px;
        padding: 0 8px 8px 8px;
        background: var(--background-color);
		box-shadow: 0 -10px 20px 0 var(--background-color);
		z-index: 1000;
		transition: all 300ms var(--ease-in-out);

		&.locating{
			.current{
				color: var(--color);
			}
		}

		&.open{
			height: calc(100vh - 48px - env(safe-area-inset-bottom));
			padding: 8px;

			.holder{
				height: 100%;
				padding-top: 0;
				padding-bottom: 0;

				.current{
					padding: 7px 5px;
					border-bottom: 1px solid var(--border-color);
				}

				.favs:not(:empty){
					display: block;
					//border-bottom: 1px solid var(--border-color);
					margin-bottom: 15px;
					padding-top: 7px;
					padding-bottom: 7px;
				}

				.search{
					display: block;
					position: relative;

					input{
						width: 100%;
						background: rgba(0, 0, 0, 0.07);
						border: 1px solid rgba(0, 0, 0, .12);
						border-radius: 10px;height: 36px;
						padding: 0 8px 0 32px;
						margin-bottom: 12px;
					}
				}

				.list{
					display: block;
					flex: 1;
				}
			}
		}
    }

	.muted{
		opacity: .6;
	}

    .holder{
		display: flex;
		flex-direction: column;
        padding: 7px 10px;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        background: var(--background-color-light);
		color: var(--color);
		overflow: hidden;

		.current{
			display: flex;

			span{
				&:not(:first-child){
					margin-left: 15px;
				}

				color: var(--color);
			}

			.loading{
				margin-top: -2px;
				margin-left: auto;
			}

			.close{
				margin-top: -2px;
				margin-left: auto;
			}
		}

		.lists{
			overflow-y: auto;
		}

		.search{
			display: none;
		}

		.favs{
			display: none;
			list-style: none;
			margin: 5px 0 0 0;
			padding: 0;
		}

		.list{
			display: none;
			list-style: none;
			margin: 5px 0 0 0;
			padding: 0;
		}

		li{
			display: flex;
			align-items: center;
			padding-right: 7px;

			strong{
				color: var(--primary-color);
			}
		}

		button{
			flex: 1;
			text-align: left;
			margin: 0 15px 7px 0;
			padding: 5px 10px;
			background: var(--background-color);
			border-radius: 4px;
			border: 1px solid rgba(0, 0, 0, .12);
			color: var(--color);
			box-shadow: 1px 2px 5px -3px rgba(0, 0, 0, .2);
			-webkit-tap-highlight-color: var(--primary-color);

			&:hover, &:active, &:focus{
				background: var(--primary-color);
				color: #fff;
			}
		}
    }
</style>
