import CONFIG from "./config";

export default {
	get: async (url, data, headers = {}) => {
		const default_headers = {
			'Content-Type': 'application/json'
		};

		try{
			const response = await fetch(CONFIG.API.URL + url, {
				method: 'GET',
				mode: 'cors',
				cache: 'no-cache',
				headers: {...default_headers, ...headers},
				body: JSON.stringify(data) // body data type must match "Content-Type" header
			});

			return response.json();
		}catch (e){
			console.log(e);
		}

		return [];
	},

	post: async (url, data, headers = []) => {
		try{
			const response =  await fetch(CONFIG.API.URL + url, {
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data) // body data type must match "Content-Type" header
			});

			return response.json();
		}catch (e){
			console.log(e);
		}

		return [];
	}
}
