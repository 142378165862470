<script>
	export let width = null;
	export let height = null;
	export let pulse = false;
</script>


<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {width} {height} class:pulse>
	<path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z" class=""></path>
</svg>


<style lang="scss">
	.pulse{
		animation: pulse 1.4s infinite;
	}

	@keyframes pulse {
		0% {
			transform: scale(0.9);
			opacity: .6;
		}

		70% {
			transform: scale(1.1);
			opacity: 1;
		}

		100% {
			transform: scale(0.9);
			opacity: .6;
		}
	}
</style>
