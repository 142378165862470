<script>
	import Cel from "../icons/cel/Cel.svelte";
	import LongArrowDown from "../icons/LongArrowDown.svelte";

	export let pronostic;

	let hores;

	$: if(pronostic?.hores){
		let ara = new Date();

		if(ara.getMinutes() >= 30) ara.setMinutes(60);
		else ara.setMinutes(30);
		ara.setSeconds(0);
		ara.setMilliseconds(0);

		hores = pronostic.hores.filter(hora => {
			const date = new Date(hora.data);

			date.setMilliseconds(0);

			return date >= ara;
		});

		hores.shift();
		if(hores.length > 30) hores.length = 30;
	}
</script>

<div class="prediccio">
	<div class="ara">
		{#if hores && hores.length > 0}
			<div class="destacat">
				<div class="temperatura">{Math.round(hores[0].temp)}º</div>
				<div class="icona"><Cel codi={hores[0].cel} hora={hores[0].hora} width="170" /></div>
			</div>
			<div class="extra">
				<ul>
					<li>Humitat: <strong>{hores[0].humitat}</strong>%</li>
					<li>Sensació: <strong>{hores[0].xafogor}º</strong></li>
					<li>Vent: <strong>{hores[0].velVent}</strong>Km/h <span class="vent" style={"transform: rotate(" + hores[0].dirVent + "deg)"}><LongArrowDown width="15" /></span></li>
				</ul>
			</div>
		{/if}
	</div>

	<div class="hores">
		{#if hores && hores.length > 0}
			<ul>
				{#each hores as hora, i}
					<li>
						<div class="dot"></div>
						<div class="hora">{hora.hora}h</div>
						<div class="icona"><Cel codi={hora.cel} hora={hora.hora} width="40" /></div>
						<div class="temperatura">{hora.temp}º</div>
					</li>
				{/each}
				<li>&nbsp;</li>
			</ul>
		{/if}
	</div>
</div>


<style lang="scss">
	.prediccio{
		position: relative;
		padding: 30px 20px 0 20px;
	}

	.ara{
		.destacat{
			display: flex;
			align-items: flex-start;
			justify-content: space-evenly;

			.temperatura{
				font-size: clamp(1rem, 25vw, 8rem);
				font-weight: 400;
				text-align: center;
			}
		}

		.extra{
			font-size: .8rem;
			white-space: nowrap;
			text-align: center;
			opacity: .8;

			ul{
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			li{
				& + li{
					&:before{
						content: '·';
						margin-left: 7px;
						margin-right: 7px;
						font-weight: bold;
						opacity: .5;
					}
				}
			}

			strong{
				font-weight: normal;
				font-size: .9rem;
			}

			.vent{
				display: inline-block;
				transform-origin: center center;
			}
		}
	}

	.hores{
		position: relative;
		margin: 10px -20px 0 -20px;
		background: var(--background-color-light);
		overflow: hidden;

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: -10px;
			bottom: 0;
			left: -10px;
			box-shadow: -5px 0 7px var(--inset-shadow-color) inset;
			pointer-events: none;
			z-index: 3;
		}

		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 3px;
			right: 0;
			bottom: 3px;
			box-shadow: -30px 0px 20px 0 var(--background-color-light) inset;
			width: 50px;
			pointer-events: none;
			z-index: 2;
		}

		ul{
			list-style: none;
			padding: 10px 20px;
			margin: 0;
			display: flex;
			flex-wrap: nowrap;
			overflow-x: auto;
			-ms-overflow-style: none; // Internet Explorer 10+
			scrollbar-width: none; // Firefox

			&::-webkit-scrollbar {
				display: none;  // Safari and Chrome
			}
		}

		li{
			text-align: center;

			&:last-child{
				margin-right: 30px;
			}

			& + li{
				margin-left: 20px;
			}

			.dot{
				position: relative;
			}
		}
	}
</style>
